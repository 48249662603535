'use client';

import React, { ReactNode } from 'react';

import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab/Tab';

import { RecapAsset } from '@/api/trading/types';
import { useRecap } from '@/api/trading/useRecap';
import { MarketsSectionTabs } from '@/blocks/sections/Markets';
import { getAssetsNamesBySlug } from '@/data';

import { CfdTable } from '@/app/[lang]/index/IndexMarketsSection/Tables/Cfd';
import { CryptoFuturesTable } from '@/app/[lang]/index/IndexMarketsSection/Tables/CryptoFutures';
import { PopularTable } from '@/app/[lang]/index/IndexMarketsSection/Tables/Popular';
import { ColumnHeaders, TableLabels } from '@/app/[lang]/index/IndexMarketsSection/Tables/types';

type Props = {
  markets: RecapAsset[];
  labels: TableLabels;
  columnHeaders: ColumnHeaders;
  cfdForexCta: ReactNode;
  cfdIndicesCta: ReactNode;
  cfdCommoditiesCta: ReactNode;
  cfdCryptoCta: ReactNode;
  cfdSharesCta: ReactNode;
  cryptoFuturesCta: ReactNode;
};

export const Tables = ({
  markets: initialMarkets,
  labels,
  columnHeaders,
  cfdForexCta,
  cfdIndicesCta,
  cfdCommoditiesCta,
  cfdCryptoCta,
  cfdSharesCta,
  cryptoFuturesCta,
}: Props) => {
  const { markets } = useRecap(undefined, initialMarkets);

  if (!markets) {
    return null;
  }

  return (
    <MarketsSectionTabs>
      <Tab disableRipple label={labels.popular} value={'1'} />
      <TabPanel value={'1'}>
        <PopularTable markets={markets} columnHeaders={columnHeaders} />
      </TabPanel>

      <Tab disableRipple label={labels.cryptoFutures} value={'2'} />
      <TabPanel value={'2'}>
        <CryptoFuturesTable markets={markets} columnHeaders={columnHeaders} />
        {cryptoFuturesCta}
      </TabPanel>

      <Tab disableRipple label={labels.forex} value={'3'} />
      <TabPanel value={'3'}>
        <CfdTable
          markets={markets}
          assetsNames={getAssetsNamesBySlug('forex')}
          columnHeaders={columnHeaders}
        />
        {cfdForexCta}
      </TabPanel>

      <Tab disableRipple label={labels.indices} value={'4'} />
      <TabPanel value={'4'}>
        <CfdTable
          markets={markets}
          assetsNames={getAssetsNamesBySlug('indices')}
          columnHeaders={columnHeaders}
        />
        {cfdIndicesCta}
      </TabPanel>

      <Tab disableRipple label={labels.commodities} value={'5'} />
      <TabPanel value={'5'}>
        <CfdTable
          markets={markets}
          assetsNames={getAssetsNamesBySlug('commodities')}
          columnHeaders={columnHeaders}
        />
        {cfdCommoditiesCta}
      </TabPanel>
      <Tab disableRipple label={labels.cfd} value={'6'} />

      <TabPanel value={'6'}>
        <CfdTable
          markets={markets}
          assetsNames={getAssetsNamesBySlug('crypto')}
          columnHeaders={columnHeaders}
        />
        {cfdCryptoCta}
      </TabPanel>
      {/*
      TODO SHARES:
      <Tab disableRipple label={labels.shares} value={'7'} />

      <TabPanel value={'7'}>
        <CfdTable
          markets={markets}
          assetsNames={getAssetsNamesBySlug('shares')}
          columnHeaders={columnHeaders}
        />
        {cfdSharesCta}
      </TabPanel> */}
    </MarketsSectionTabs>
  );
};
